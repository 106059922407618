import React from "react";
import ReactDOM from "react-dom";
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react'

import { App } from "@components";
import { isProduction } from "@shared/utilities/env";

// Matomo (https://www.npmjs.com/package/@datapunt/matomo-tracker-react)
const instance = createInstance({
  urlBase: 'https://voloocpter.matomo.cloud/',
  siteId: 4
})

const render = (element) => {
  const config = element.dataset;
  ReactDOM.render(
    // <App config={config} />, element
    <MatomoProvider value={instance}>
      <App config={config} />
    </MatomoProvider>, element
  );
};

console.warn(`Application Production: ${isProduction()}`);
render(document.querySelector(`.app`));
