import React from "react";
import axios from "axios";

import { isProduction } from "@shared/utilities/env";
import { sanitizePage } from "@shared/api/sanitize";

const BASENAME = isProduction() ? "/" : "http://localhost:8888/bp-backend/";

export const api = {
  fetchPage: (page, options = {}) => {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${BASENAME}api/wp/v2/pages/${page.id}`, {
          params: Object.assign({}, options),
        })
        .then((response) => {
          if (response.data) {
            resolve(sanitizePage(page, response.data));
          } else {
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    });
  },
  fetchSitemap: (options = {}) => {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${BASENAME}api/wp/v2/sitemap`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          reject();
        });
    });
  },
  fetchOptions: (options = {}) => {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${BASENAME}api/acf/v3/options/options`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          reject();
        });
    });
  },
  login: () => {
    return new Promise((resolve, reject) => {
      return axios
        .get(`${BASENAME}api/wp/v2/login`)
        .then((response) => {
          resolve(response.data);
        })
        .catch(() => {
          reject();
        });
    });
  },
};

export default React.createContext();
