import React from 'react'
import GridContainer from '@components/gridContainer'
import { Video as VideoWrapless } from "@components/video";
import { Headline as HeadlineWrapless } from "@components/headline";
import { Paragraph as ParagraphWrapless } from "@components/paragraph";
import { gridCellSize } from "@shared/utilities/helper";

import './videoText.scss'

export const VideoText = (props) => {
  return (
    <>
      <div className={`videoText${props.modifier ? ` ${props.modifier}` : ''}`}>
          {props.videos.map((video, index) => {
            return (
              <div className="grid-container" key={index}>
                <div className={video.videocontent.width == '50' ? `grid-cell--6` : `grid-cell--8`}>
                  <VideoWrapless src={video.videocontent.file.url} width={"100"} caption={""} poster={""} border={video.videocontent.border}></VideoWrapless>
                </div>
                <div className={video.videocontent.width == '50' ? `grid-cell--6` : `grid-cell--4`}>
                  <div className="">
                    <div className="videoText-title">{video.textcontent.headline}</div>
                    <ParagraphWrapless text={video.textcontent.description}></ParagraphWrapless>
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}

export default GridContainer(VideoText)