import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { motion, AnimateSharedLayout, AnimatePresence } from 'framer-motion';
import { Link, useLocation, NavLink, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";

// import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron-menu.svg";
import { ReactComponent as BurgerIcon } from "../../assets/icons/burger.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import { ReactComponent as VCSign } from "../../assets/icons/vc-sign-white.svg";
import { ReactComponent as VCLogo } from "../../assets/icons/vc-logo-white.svg";
// import logo from '../../assets/images/vc-logo.png';
import { getPageFromSitemap } from "@shared/app";

import "./sidebar.scss";

export default () => {
  const history = useHistory();
  const [sidebar, setSidebar] = useState('is-closed');
  const [menu, setMenu] = useState('is-closed');
  const [openSubID, setOpenSubID] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);
  const sitemap = useSelector((state) => state.sitemap);
  const currentPage = useSelector((state) => state.page);
  const currentLanguage = useSelector((state) => state.preferences.language);
  const { pathname } = useLocation();
  const openSubAtStart = "";
  const menuItems = sitemap[currentLanguage].filter((item) => {
    // {item.url === parentDirectory(pathname) ? setOpenSubID(item.id) : ""}
    // {
    //   useEffect(() => {
    //     item.url === parentDirectory(pathname) ? setOpenSubID(item.id) : ""
    //   });
    // }
    // item.parentURL = parentDirectory(item.url);
    return item.title != "Home" && item.title != "首页" && item.title != "Imprint" && item.title != "印刷制品";
  });

  console.log("SITEMAP", sitemap);
  console.log("CURRENT PAGE", currentPage);
  console.log("CURRENT LANGUAGE", currentLanguage);
  console.log("MENU ITEMS", menuItems);

  const body = document.querySelector("body")

  function handleBurgerClick(e) {
    setSidebar(sidebar == "is-closed" ? "is-open" : "is-closed")
    let currentScrollPosition = document.documentElement.scrollTop
    if (sidebar == "is-closed") {
      setScrollPosition(currentScrollPosition)

      // body.style.overflow = "hidden"
      body.style.top = -1 * currentScrollPosition + "px"
      body.style.position = "fixed"
      body.style.overflowY = "scroll"
      body.style.width = "100%"
    } else {
      // body.style.overflow ="visible"
      body.style.position ="static"
      body.style.overflowY ="auto"
      body.style.width ="auto"
      document.documentElement.scrollTop = scrollPosition
    }
  }

  function parentDirectory(path) {
    const lastChar = path.substr(-1); // Selects the last character
    if (lastChar !== '/') {         // If the last character is not a slash
      return getParentDirectory(path) + "/"
    } else {
      let pathWithSlashRemoved = path.replace(/\/$/, "")
      return getParentDirectory(pathWithSlashRemoved) + "/"
    }
  
    function getParentDirectory(dir) {
      const lastSlash = dir.lastIndexOf('/');
      if (lastSlash === -1) {
        return dir;
      }
      if (lastSlash === 0) {
        return '/';
      }
      return dir.substring(0, lastSlash);
    }
  }

  const handleSwitchLanguage = (language) => {
    if (currentPage.meta) {
      const translations = currentPage.meta.translations;
      if (translations && translations[language]) {
        const translatedPage = getPageFromSitemap(
          translations[language],
          "id",
          language
        );
        history.push(translatedPage ? translatedPage.url : `/${language}`);
      } else {
        history.push(`/${language}`);
      }
    } else {
      history.push(`/${language}`);
    }
  };

  const MainLink = (props) => {
    let activeItem = false
    if (props.url === pathname) {
      activeItem = true;
    }
    return (<>
      <a href={"https://brand.volocopter.com" + props.url} className={`sidebar-menuItem sidebar-link${activeItem ? " sidebar-menuItem--active" : ""}`}>{props.title}</a>
    </>)
  }

  const SubLink = (props) => {
    let activeItem = false
    if (props.url === pathname) {
      activeItem = true;
      // activeParent = props.parentID
      // useEffect(() => {
      //   setOpenSubID(props.parentID)
      // });
      console.log("PARENT ID: ", props.parentID);
    }
    return (
      <a href={"" + props.url} className={`sidebar-menuItem sidebar-subLink${activeItem ? " sidebar-menuItem--active" : ""}`}>{props.title}</a>
    )
  }

  const SubContent = (props) => {
    console.log("PATH NAME: ", pathname);
    console.log("PATH NAME CUT: ", pathname.substring(0, pathname.lastIndexOf('/')));
    return (
      <div className="sidebar-subContent">
        {props.items.map((item) => (
          <SubLink url={item.url} title={item.title} id={item.id} parentID={props.parentID} key={item.id} />
        ))}
      </div>
    )
  }

  const LegalLink = (props) => {
    let activeItem = false
    if (props.url === pathname) {
      activeItem = true;
    }
    return (
      <a href={"" + props.url} className={`sidebar-legalLink${activeItem ? " sidebar-menuItem--active" : ""}`}>{props.title}</a>
    )
  }

  const LangSwitch = (props) => {
    return (
      <div className="sidebar-langSwitch">
        <span className={`sidebar-langSwitch-lang sidebar-langSwitch-lang--en${ currentLanguage == "en" ? " is-active" : "" }`} onClick={(event) => handleSwitchLanguage("en")}>EN</span>
        <span className={`sidebar-langSwitch-lang sidebar-langSwitch-lang--cn${ currentLanguage == "cn" ? " is-active" : "" }`} onClick={(event) => handleSwitchLanguage("cn")}>中文</span>
      </div>
    )
  }

  // const SubContent = () => {
  //   return (<>
  //     <div className="sidebar-subContent">
  //       <a href="/vc/en/logo/" className="sidebar-menuItem sidebar-subLink">Logo</a>
  //       <a href="/vc/en/colours/" className="sidebar-menuItem sidebar-subLink">Colours</a>
  //       <a href="/vc/en/typography/" className="sidebar-menuItem sidebar-subLink">Typography</a>
  //       <a href="/vc/en/image-style/" className="sidebar-menuItem sidebar-subLink">Imagery</a>
  //       <a href="/vc/en/layout-structure/" className="sidebar-menuItem sidebar-subLink">Layout Structure</a>
  //       <a href="/vc/en/icons/" className="sidebar-menuItem sidebar-subLink">Icons</a>
  //       <a href="./../" className="sidebar-menuItem sidebar-subLink">Animation</a>
  //     </div>
  //   </>)
  // }

  useEffect(() => {
    // Update the document title using the browser API    document.title = `You clicked ${count} times`;
    console.log("USEEFFECT")
  });

    return (<>
        <div className={`sidebar ${sidebar}`}>
          <div className="sidebar-cover" onClick={handleBurgerClick}></div>
          <div className="sidebar-content">
            <div className="sidebar-toggle" onClick={handleBurgerClick}>
              {sidebar == "is-open" &&
              <CloseIcon className="sidebar-closeIcon"/>
              }
              {sidebar == "is-closed" &&
              <BurgerIcon className="sidebar-burgerIcon"/>
              }
            </div>
            <a href={currentLanguage == "cn" ? "/cn/" : "/en/"} className="sidebar-logo">
              {sidebar == "is-open" &&
                <div className="sidebar-vcLogoWrapper"><VCLogo className="sidebar-vcLogo"/></div>
              }
              {sidebar == "is-closed" &&
                <div className="sidebar-vcSignWrapper"><VCSign className="sidebar-vcSign"/></div>
              }
            </a>
            <div className="sidebar-menu">
              <a href={currentLanguage == "cn" ? "/cn/" : "/en/"} className="sidebar-menuItem sidebar-link">{currentLanguage == "cn" ? "首页" : "Home"}</a>
              {/* <div className={`sidebar-sub ${menu}`}>
                <div className="sidebar-subParent sidebar-menuItem" onClick={() => setMenu(menu == "is-closed" ? "is-open" : "is-closed")}>
                  Design Basics
                  <ChevronIcon className="sidebar-chevronIcon"/>
                </div>
                {menu == "is-open" &&
                  <SubContent />
                }
              </div> */}

              {/* {menuItems && (<>

              </>)} */}

              {menuItems.map((item) => (
                <React.Fragment key={item.id}>

                {/* If submenu */}
                {item.children.length > 0 && (
                  // <div className={`sidebar-sub ${menu}`}>
                  <div className={`sidebar-sub ${openSubID != item.id ? "is-closed" : ""}`}>
                    {/* <div className="sidebar-subParent sidebar-menuItem" onClick={() => setOpenSubID(openSubID == "is-closed" ? "is-open" : "is-closed")}> */}
                    {/* {parentDirectory(pathname) === item.url ? setOpenSubID(item.id) : ""} */}
                    <div className="sidebar-subParent sidebar-menuItem" onClick={() => setOpenSubID(openSubID != item.id ? item.id : 0)}>
                      {item.title}
                      <ChevronIcon className="sidebar-chevronIcon"/>
                    </div>
                    {openSubID == item.id &&
                      <SubContent items={item.children} parentID={item.id} />
                    }
                  </div>
                )}

                {/* If single link */}
                {item.children.length == 0 && (
                  <MainLink id={item.id} url={item.url} title={item.title} />
                )}

                </React.Fragment>
              ))}

              {/* <LegalLink url={'#'} title={'Imprint'}/> */}

              {/* <a href="./../" className="sidebar-menuItem sidebar-link">Menu Link</a> */}
              {/* <a href="./../" className="sidebar-menuItem sidebar-link">Menu Link</a> */}
            </div>
            <div className="sidebar-legal">
              {/* <a href="./../" className="sidebar-legalLink">Kontakt</a> */}
              {/* <a href="./../" className="sidebar-legalLink">Imprint</a> */}
              <LegalLink url={currentLanguage == "cn" ? "/cn/imprint/" : "/en/imprint/"} title={currentLanguage == "cn" ? "印刷制品" : "Imprint"}/>
            </div>
            <LangSwitch />
          </div>
        </div>
    </>);
};
