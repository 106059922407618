import React from 'react'
import GridContainer from '@components/gridContainer'
import { Headline as HeadlineWrapless } from "@components/headline";

import './colors.scss'

export const Colors = (props) => {

  // set grid cell width according to row style (3 or 4 items)
  const gridCellClass = `colors-item${props.style.row == 3 ? ` grid-cell--4` : ` grid-cell--3`}`

  return (
    <>
      <div className={`colors${props.modifier ? ` ${props.modifier}` : ''}`}>
        <div className="grid-container">
          {props.items.map((item, index) => {
            const colorAreaStyle = {
              backgroundColor: `${item.colorarea.useimage == false ? `#${item.colorarea.hex}`: 'none'}`
            };

            const addborder = item.colorarea.addborder
            const useimage = item.colorarea.useimage

            const ColorImage = (props) => {
              if (item.colorarea.useimage == true) {
                return (
                  <picture>
                    <source media="(min-width:300px)" srcSet={item.colorarea.colorimage.sizes.custom_600} />
                    <img className="colors-image" src={item.colorarea.colorimage.sizes.custom_300} alt="Image of color" />
                  </picture>
                );
              } else {
                return (
                  null
                );
              }
            };

            return (
              <div className={gridCellClass} key={index}>
                <div className={`colors-color${props.style.size ? ` colors-color--${props.style.size}` : ''}${useimage ? " colors-color--image" : ""}${addborder ? " colors-color--border" : ""}`} style={colorAreaStyle}>
                  <ColorImage className={`colors-image${props.style.size ? ` colors-image--${props.style.size}` : ''}`}></ColorImage>
                </div>
                <div className="colors-title">{item.colorname}</div>
                <div className="colors-values">
                  {item.values.rgb ?
                    (
                      <div className="colors-value"><div className="colors-key">RGB</div>{item.values.rgb}</div>
                    )
                    :
                    (null)
                  }
                  {item.values.hex ?
                    (
                      <div className="colors-value"><div className="colors-key">HEX</div>{item.values.hex}</div>
                    )
                    :
                    (null)
                  }
                  {item.values.cmyk ?
                    (
                      <div className="colors-value">
                        <div className="colors-key">CMYK</div>
                        <div>
                          <div>{item.values.cmyk}</div>
                          {item.values.cmyk_2 &&
                          <div>{item.values.cmyk_2}</div>
                          }
                        </div>
                      </div>
                    )
                    :
                    (null)
                  }
                  {item.values.ral ?
                    (
                      <div className="colors-value">
                        <div className="colors-key">RAL</div>
                        <div>
                          <div>{item.values.ral}</div>
                          {item.values.ral_2 &&
                          <div>{item.values.ral_2}</div>
                          }
                        </div>
                      </div>
                    )
                    :
                    (null)
                  }
                  {item.values.pantone ?
                    (
                      <div className="colors-value"><div className="colors-key">PANTONE</div>{item.values.pantone}</div>
                    )
                    :
                    (null)
                  }
                  {item.values.hks ?
                    (
                      <div className="colors-value"><div className="colors-key">HKS</div>{item.values.hks}</div>
                    )
                    :
                    (null)
                  }
                  {item.values.scotchcal ?
                    (
                      <div className="colors-value"><div className="colors-key">3M Scotchcal</div>{item.values.scotchcal}</div>
                    )
                    :
                    (null)
                  }
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default GridContainer(Colors)