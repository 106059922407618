import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import Headline from "@components/headline";
import Paragraph from "@components/paragraph";
import RelatedContent from "@components/relatedContent";
import Divider from "@components/divider";
import Image from "@components/image"; // default export is wrapped in HOC
import ImageRow from "@components/imageRow";
import ImageText from "@components/imageText";
import DownloadList from "@components/downloadList";
import Category from "@components/category";
import Slider from "@components/slider";
import SliderRow from "@components/sliderRow";
import ReadNext from "@components/readNext";
import SliderText from "@components/sliderText";
import Comparison from "@components/comparison";
import RichText from "@components/richText";
import TextCards from "@components/textCards";
import Colors from "@components/colors";
import FormatsTable from "@components/formatsTable";
import VideoRow from "@components/videoRow";
import VideoText from "@components/videoText";
import {Hero} from "@components/hero";
import MasonryGallery from "@components/masonryGallery";
import IconLibrary from "@components/iconLibrary";

const components = {
  headline: Headline,
  divider: Divider,
  paragraph: Paragraph,
  related_content: RelatedContent,
  readNext: ReadNext,
  image: Image,
  textimage: ImageText,
  imagerow: ImageRow,
  downloadList: DownloadList,
  category: Category,
  slider: Slider,
  sliderrow: SliderRow,
  textslider: SliderText,
  comparison: Comparison,
  richtext: RichText,
  textcards: TextCards,
  colors: Colors,
  formatstable: FormatsTable,
  video: VideoRow,
  videotext: VideoText,
  inspiration: MasonryGallery,
  iconlibrary: IconLibrary,
};

const Section = (props) => {
  return (
    <div
      className={`section${
        props.backgroundColor ? " section--backgroundColor" : ""
      }`}
    >
      {props.children}
    </div>
  );
};

const createPageContent = (hero, content, downloads, readnext) => {
  if (content) {
    return (
      <>
        {(hero && hero.image.url) &&
          <Hero image={hero.image.sizes.custom_2400} headline={hero.headline} />
        }

        {(hero && hero.usevideo == true) &&
          <Hero video={hero.videocontent.videofile} />
        }

        {content.map((section, key) => {
          return (
            <Section backgroundColor={section.backgroundColor} key={key}>
              {section.modules.map((module, key) => {
                const moduleName = components[module.acf_fc_layout];
                if (moduleName) {
                  return React.createElement(
                    moduleName,
                    Object.assign({}, module, { key: key })
                  );
                }
                return <></>;
              })}
            </Section>
          );
        })}

        {downloads && downloads[0].file && (
          <>
            <div className="section downloadList-wrapper">
              <DownloadList
                downloadListItems={downloads}
                cellSize="12"
                gridModifier="noSpacingTopBottom"
                key="download"
              />
            </div>
          </>
        )}

        {(readnext.previous != false || readnext.next != false) && (
          <>
            <div className="section readNext-wrapper">
              <ReadNext prevnext={readnext} cellSize="12" key="readNext" />
            </div>
          </>
        )}
      </>
    );
  }
  return "";
};

export default (props) => {
  const isLoading = useSelector((state) => state.application.loading);
  const page = useSelector((state) => state.page);
  const [content, setContent] = useState(<></>);

  useEffect(() => {
    setContent(createPageContent(page.hero, page.content, page.downloads, page.readNext));
  }, [page.content]);

  return (
    <motion.div
      className="content"
      initial="hidden"
      animate={isLoading === true ? "hidden" : "visible"}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 0 },
      }}
    >
      {content}
    </motion.div>
  );
};
