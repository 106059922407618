import React from "react";

import BrandPlatform from "@shared/app";
import Content from "@components/content";
import Sidebar from "@components/sidebar";

import "./app.scss";

// TODO: WHAT IS THIS? (A: Shows component name in react devtools, instead of "Anonymous")
BrandPlatform.displayName = "Brand Platform";

export default (props) => {
  return (
    <BrandPlatform config={props.config}>
      <Sidebar />
      <Content />
    </BrandPlatform>
  );
};
