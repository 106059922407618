import React from "react"
import GridContainer from "@components/gridContainer";
import { Headline as HeadlineWrapless } from "@components/headline";
import { Paragraph as ParagraphWrapless } from "@components/paragraph";
import { Image as ImageWrapless } from "@components/image";
import { Video as VideoWrapless } from "@components/video";

import './hero.scss'

export const Hero = (props) => {

  if (props.add_image) {
    return (
      <>
        <div>HERO</div>
      </>
    )
  }
  if (props.video) {
    return (
      <div className={`hero${props.modifier ? ` hero--${props.modifier}` : ''}`}>
        <VideoWrapless src={props.video.url} width={'100'} caption={false} border={false}></VideoWrapless>
      </div>
    )
  }
  return (
    <>
      <div className={`hero${props.modifier ? ` hero--${props.modifier}` : ''}`}>
        <img className="hero-image" src={props.image} alt="Hero image" />
        <div className="hero-headlineWrapper">
          <div className="hero-headline">{props.headline}</div>
        </div>
      </div>
    </>
  )
}

export default GridContainer(Hero)