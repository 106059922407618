import React, { useEffect, useState } from "react";
import GridContainer from "@components/gridContainer";
import { RichText as RichTextWrapless } from "@components/richText";
import axios from "axios";
import { isProduction } from "@shared/utilities/env";
import { useSelector } from "react-redux";

import "./iconLibrary.scss";

const Icon = (props) => {
  return (
    <a href={props.icon_file.url} className="iconLibrary-Icon">
      <div className="iconLibrary-IconBackground">
        <div className={`iconLibrary-IconWrapper iconLibrary-IconWrapper--light ${"iconLibrary-IconWrapper--" + props.icon_size}`}>
          <img src={props.icon_bright.url} alt="Icon brightblue" />
          {/* <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="2 2 20 20"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg> */}
        </div>
        <div className={`iconLibrary-IconWrapper iconLibrary-IconWrapper--dark ${"iconLibrary-IconWrapper--" + props.icon_size}`}>
          <img src={props.icon_dark.url} alt="Icon darkblue" />
          {/* <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="2 2 20 20"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path></svg> */}
        </div>
      </div>
      <div className="iconLibrary-IconTitle">
        {(props.currentLanguage == "cn" && props.icon_name_cn) ? props.icon_name_cn : props.icon_name}
      </div>
    </a>
  )
}

const Category = (props) => {
  let downloadAllURL = ""
  function getDownloadURLs() {
    let allZipIds = []
    props.icons.forEach(icon => {
      let id = icon.icon_file.id
      allZipIds.push(id)
    });

    const basePath = isProduction()
    ? "/api/wp/v2/download?"
    : "http://localhost:8000/api/wp/v2/download?";
    let downloadPath = basePath
    allZipIds.forEach((id, index) => {
      if (allZipIds.length > 1) {
        if (index == allZipIds.length - 1) {
          downloadPath = downloadPath.concat(`id[]=${id}`)
        } else {
          downloadPath = downloadPath.concat(`id[]=${id}&`)
        }
      } else {
        downloadPath = downloadPath.concat(`id[]=${id}`)
      }
    });
    downloadAllURL = downloadPath
  }
  getDownloadURLs()

  
  return (
    <div className="iconLibrary-category">
      <div className="iconLibrary-categoryHeader">
        {(props.subcategory) ?
          <div className="iconLibrary-subcategoryTitle">{(props.currentLanguage == "cn" && props.category_name_cn) ? props.category_name_cn : props.category_name}</div>
          :
          <div className="iconLibrary-categoryTitle">{(props.currentLanguage == "cn" && props.category_name_cn) ? props.category_name_cn : props.category_name}</div>
        }
        <a href={downloadAllURL} download className="iconLibrary-categoryDownload">{props.currentLanguage == "cn" ? "下载本组图标" : "Download set"}</a>
      </div>
      <div className="iconLibrary-Icons">

        {props.icons.map((icon, index) =>
          <Icon {...icon} currentLanguage={props.currentLanguage} key={index}/>
          )}

      </div>
    </div>
  )
}

export const IconLibrary = (props) => {
  const BASENAME = isProduction()
  ? "/"
  : "http://localhost:8000/";

  const currentPage = useSelector((state) => state.page);
  const currentLanguage = useSelector((state) => state.preferences.language);

  const [icons, setIcons] = useState([])
  useEffect(() => {
    axios
    .get(`${BASENAME}api/acf/v3/options/options`)
    .then((response) => {
      setIcons(response.data);
    })
  }, [])

  let downloadAllURL = ""
  if (icons.acf) {
    function getDownloadAllURL() {
      let allZipIds = []
      let categories = icons.acf.icons.categories
      categories.forEach(category => {
        let icons = category.icons
        icons.forEach(icon => {
          let url = icon.icon_file.id
          allZipIds.push(url)
        });
      });

      const basePath = isProduction()
      ? "/api/wp/v2/download?"
      : "http://localhost:8000/api/wp/v2/download?";
      let downloadPath = basePath
      allZipIds.forEach((id, index) => {
        if (allZipIds.length > 1) {
          if (index == allZipIds.length - 1) {
            downloadPath = downloadPath.concat(`id[]=${id}`)
          } else {
            downloadPath = downloadPath.concat(`id[]=${id}&`)
          }
        } else {
          downloadPath = downloadPath.concat(`id[]=${id}`)
        }
      });
      downloadAllURL = downloadPath
    }
    getDownloadAllURL()
  }

  return (
    <>
      {icons.acf &&
          <div
          className={`iconLibrary${props.modifier ? ` ${props.modifier}` : ""}`}
        >
          <div className="iconLibrary-downloadAll">
            <a href={downloadAllURL} download className="iconLibrary-downloadAllLink">{currentLanguage == "cn" ? "下载所有图标" : "Download all icons"}</a>
          </div>

          {icons.acf.icons.categories.map((category, index) =>
            <Category {...category} currentLanguage={currentLanguage} key={index}/>
          )}

        </div>
      }
    </>
  );
};

export default GridContainer(IconLibrary);
