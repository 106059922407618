import { url } from "@shared/app/routes";

export const sanitizeMenuItems = (menuItems) => {
  return menuItems.map((item) => {
    return {
      title: item.title.rendered,
      slug: item.slug,
    };
  });
};

const sanitizeSection = (section) => {
  return {
    backgroundColor: section["background_gray"],
    modules:
      section.modules !== false
        ? section.modules.map((module) => {
            return module;
          })
        : [],
  };
};

const sanitizeReadNext = (readnext) => {
  return {
    next:
      readnext.next !== false
        ? {
            id: readnext.next.ID,
          }
        : false,
    previous:
      readnext.previous !== false
        ? {
            id: readnext.previous.ID,
          }
        : false,
  };
};

export const sanitizePage = (page, content) => {
  let sanitizedPage = {
    meta: page,
    tmp: content.acf,
    title: content.title.rendered,
    hero: content.acf.hero,
  };
  if (content.acf.sections) {
    sanitizedPage = Object.assign({}, sanitizedPage, {
      content: content.acf.sections.map((section) => sanitizeSection(section)),
    });
  }
  if (content.acf.downloadlist) {
    sanitizedPage = Object.assign({}, sanitizedPage, {
      downloads: content.acf.downloadlist.downloads,
    });
  }
  if (content.acf.readnext) {
    sanitizedPage = Object.assign({}, sanitizedPage, {
      readNext: sanitizeReadNext(content.acf.readnext),
    });
  }
  return sanitizedPage;
};
