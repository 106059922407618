import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCookie } from "tiny-cookie";

import { t } from "@shared/app";
import { setAuthenticated } from "@shared/app/store";

import { ReactComponent as LogoIcon } from "../../assets/icons/vc-logo-black.svg";

import "./login.scss";

const LoginPage = () => {
  const dispatch = useDispatch();
  const language = useSelector((state) => state.preferences.language);

  const nameInput = useRef();
  const passwordInput = useRef();

  const handleSubmit = useCallback(() => {
    const name = nameInput.current.value;
    const password = passwordInput.current.value;
    if (name == "Volocopter" && password == "BrandPortal360") {
      setCookie("vol-bp", "token");
      dispatch(setAuthenticated(true));
    }
  }, [nameInput, passwordInput]);

  return (
    <>
      <div className="login-logo">
        <LogoIcon />
      </div>
      <div className="login-dotBg" />
      <div className="login">
        <div className="login-poster" />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="login-form"
        >

          <div className="login-headline login-headline--bold">Volocopter</div>
          <div className="login-headline login-headline--light">{language == "cn" ? "品牌门户" : "brand portal"}</div>

          <input ref={nameInput} type="text" placeholder="Username" />
          <input ref={passwordInput} type="password" placeholder="Password" />
          <p>
            {language == "cn" ? "需要访问？仅需发送请求至" : "Need access? Just send a request to"}
            <a href="mailto:brandportal@volocopter.com">
              brand@volocopter.com
            </a>
          </p>
          <input type="submit" value={t("Login", language)} />
        </form>
      </div>
    </>
  );
};

export default LoginPage;
