import React from 'react'
import GridContainer from '@components/gridContainer'
import './headline.scss'

export const Headline = (props) => {
  const HeadlineTag = `h${props.priority}`;
  return (
    <>
      <HeadlineTag className={`headline${props.modifier ? ` ${props.modifier}` : ``}${props.priority ? ` headline--h${props.priority}` : ``}`}>{props.text}</HeadlineTag>
      {props.addsubheadline &&
        <div className="headline-subheadline">
          <HeadlineTag className={`headline${props.subheadline.modifier ? ` ${props.subheadline.modifier}` : ``}${props.subheadline.priority ? ` headline--h${props.subheadline.priority}` : ``}`}>{props.subheadline.text}</HeadlineTag>
        </div>
      }
    </>
  )
}

Headline.defaultProps = {
  priority: 1
}

export default GridContainer(Headline, {spacing: "noSpacingBottom"})
